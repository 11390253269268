import styled from 'styled-components';
import BannerBG from '../../../assets/image/banner-texture.png';

export const ContainerWrapper = styled.div`
    padding: 115px 0 25px 0;
`;

export const TileFlexRow = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;

    background-image: url(${BannerBG}),
    linear-gradient(35deg, rgb(99, 23, 25, 39) 0%, rgb(176, 58, 62, 69) 100%);
    background-size: cover;
    background-position: top center;

    @media (max-width: 1260px) {
        justify-content: center;
        align-items: center;
    }
`;

export const ServiceSectionTile = styled.div`
    border-radius: .19571429rem;
    box-shadow: 0 1px 1px 1px #d4d4d5, 0 0 0 1px #d4d4d5;
    max-width: 250px;

    height: 100%;
    width: 100%;
    margin: 15px 5px 25px 5px;

    a {
        color: #000000;
    }

    @media (min-width: 1060px) and (max-width: 1299px) {
        margin: 15px 125px 25px 5px;
        max-width: 450px;
    }

    @media (min-width: 930px) and (max-width: 1059px) {
        margin: 15px 50px 25px 5px;
        max-width: 500px;
    }
`;

export const ServiceSubSectionTile = styled.div`
    border-radius: .19571429rem;
    box-shadow: 0 1px 1px 1px #d4d4d5, 0 0 0 1px #d4d4d5;
    line-height: 1.2;
    letter-spacing: -0.025em;

    height: 100%;
    width: 100%;
    margin: 10px 5px 0 5px;

    a {
        color: #000000;
    }

    @media (min-width: 1060px) and (max-width: 1239px) {
        margin: 15px 125px 25px 5px;
    }

    @media (min-width: 930px) and (max-width: 1059px) {
        margin: 15px 50px 25px 5px;
    }
`;

export const ServicesTileContent = styled.div`
    padding: 10px;
    background: #ffffff;
    min-height: 150px;

    .section-header {
        font-size: 1.5rem;
        text-align: center;
        font-weight: bold;
    }

    .section-content {
        padding-top: 10px;
        font-size: 1rem;
        text-align: center;
    }
`;

export const ServicesSubTileContent = styled.div`
    padding: 10px;

    .section-header {
        font-size: 1.5rem;
        text-align: center;
        font-weight: bold;
    }

    .section-content {
        padding-top: 10px;
        font-size: 1.25rem;
        text-align: center;
    }
`;

export const ContentContainer = styled.div`
    margin: 15px 25px 15px 25px;

    border-radius: .19571429rem;
    box-shadow: 0 1px 1px 1px #d4d4d5, 0 0 0 1px #d4d4d5;

    .panel-header {
        font-size: 1.75rem;
        padding: 10px;
    }
`;

export const ContentRow = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
`;

export const ContentRowHeader = styled.div`
    border-radius: .19571429rem;
    box-shadow: 0 1px 1px 1px #d4d4d5, 0 0 0 1px #d4d4d5;
    
    padding: 10px 0 10px 0;
    margin-bottom: 15px;
    color: #ffffff;
    font-size: 1.75rem;
    font-weight: 500;
    line-height: '1.2';
    letter-spacing: '-0.025em';
    text-align: center;
    
    background-image: url(${BannerBG}),
    linear-gradient(35deg, rgba(99, 23, 25, 0.8) 0%, rgba(176, 58, 62, 0.8) 100%);
    width: 100%;
`;

export const OverviewPanel = styled.div`
    padding: 15px 15px 15px 15px;
    font-size: 1.15rem;
    line-height: 1.5;
    letter-spacing: 0.01rem;

    width: 50%;

    @media (max-width: 650px) {
        width: 100%;
    }

    .overview-panel-list-style {
        padding-left: 1.25rem;
        padding-bottom: 15px;
        margin-left: 1rem;

        li {
            list-style-type: disc;
            list-style-position: outside;
        }
    }

    .statistic-attribution {
        font-style: italic;
        font-size: 0.95rem;
    }
`;

export const DetailsPanel = styled.div`
    font-size: 1.25rem;
    line-height: 1.25;
    letter-spacing: 0.01rem;
    padding: 15px 25px 15px 25px;
    background-image: url(${BannerBG}),
    linear-gradient(35deg, rgba(99, 23, 25, 0.8) 0%, rgba(176, 58, 62, 0.8) 100%);
    color: #ffffff;
    
    
    min-height: 400px;
    width: 50%;

    @media (max-width: 650px) {
        width: 100%;
    }

    .details-header {
        font-size: 1.75rem;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .details-panel-list-style {
        padding-left: 1.25rem;
        padding-bottom: 10px;

        li {
            list-style-type: disc;
            list-style-position: outside;
        }
            
        
    }
`;

export const ScrollToTopButton = styled.div`
    width: 50px;
    height: 50px;

    button {
        display: inline-block;
        background-color: #edcd36;
        width: 50px;
        height: 50px;
        text-align: center;
        border-radius: 4px;
        position: fixed;
        bottom: 30px;
        right: 30px;
        transition: background-color .3s, 
        opacity .5s, visibility .5s;
        opacity: 0;
        visibility: hidden;
        z-index: 9999;
    }

    button:hover {
        cursor: pointer;
    }
`;

export const ScrollToSectionButton = styled.div`
    width: 200px;
    height: 100%;
    margin: auto;
    margin-top: 10px;

    button {
        background-color: #edcd36;
        font-size: 1rem;
        text-align: center;
        border-radius: 4px;
    }

    button:hover {
        cursor: pointer;
    }
`;

export const ParallaxImageTextOverlayWrapper = styled.section`
    color: #ffffff;
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.025em;
    text-align: center;
    padding: 15px 40px 15px 40px;
    background-image: url(${BannerBG}),
    linear-gradient(35deg, rgba(99, 23, 25, 0.7) 0%, rgba(176, 58, 62, 0.7) 100%);
    width: 100%;

    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%,-50%);
`;

export const ParallaxImageWrapper = styled.div`
    height: 250px;
`;