import React, { Fragment } from 'react'
import { ThemeProvider } from 'styled-components'
import Sticky from 'react-stickynode'

import { raveniiTheme } from '../theme/ravenii'
import { ResetCSS } from '../assets/css/style'
import { GlobalStyle, ContentWrapper } from '../components/layout/container.style'

import { DrawerProvider } from '../components/common/contexts/DrawerContext'
import Seo from '../components/seo'
import Navbar from '../components/layout/Navbar'
import ServicesLandingSection from '../components/containers/ServicesLanding'
import ContactUsSection from '../components/containers/ContactUs'
import Footer from '../components/layout/Footer'

const ServicesPage = () => {
  return (
    <ThemeProvider theme={raveniiTheme}>
      <Fragment>
        <Seo title='RAVENii | Services' />

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass='sticky-nav-active'>
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>

          <ServicesLandingSection />
          <ContactUsSection />

          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  )
}

export default ServicesPage
