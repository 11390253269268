import React, { Fragment, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Scrollspy from 'react-scrollspy'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Parallax } from 'react-parallax'
import { FiChevronUp, FiChevronDown } from 'react-icons/fi'

import Text from '../../atoms/Text'

import {
  ContainerWrapper,
  TileFlexRow,
  ServiceSectionTile,
  ServiceSubSectionTile,
  ServicesTileContent,
  ServicesSubTileContent,
  ContentContainer,
  ContentRow,
  ContentRowHeader,
  DetailsPanel,
  OverviewPanel,
  ScrollToTopButton,
  ScrollToSectionButton,
  ParallaxImageTextOverlayWrapper,
  ParallaxImageWrapper,
} from './servicesLanding.style'

import securityOperationsCenterParallaxImage from '../../../images/services-soc-parallax.jpg'
import incidentResponseParallaxImage from '../../../images/services-incident-response-parallax.jpg'
import consultingImage from '../../../images/services-consulting.jpg'
import testsAndAuditsImage from '../../../images/services-tests-audits.jpg'
import managedServicesParallaxImage from '../../../images/services-managed-services-parallax.jpg'

const ServicesLandingSection = ({ imageStyle, subTitle, statisticsAttribution }) => {
  const scrollToTopButtonElementId = 'scrollToTopButton'
  const [isScrollToTopButtonVisible, setScrollTopButtonVisible] = useState(false)

  // @NOTE: this check is only to safeguard node tests that don't have access to window like the browser
  // @TODO: could probably break that component out into it's own thing and manage this through the state of that component
  if (typeof window !== 'undefined') {
    window.onscroll = () => {
      let currentlyScrolledFromTop = window.pageYOffset
      if (currentlyScrolledFromTop > 0 && currentlyScrolledFromTop > 499) {
        if (!isScrollToTopButtonVisible) {
          setScrollTopButtonVisible(true)
          let scrollToTopButtonEl = document.getElementById(scrollToTopButtonElementId)
          scrollToTopButtonEl.style.visibility = 'visible'
          scrollToTopButtonEl.style.opacity = '0.8'
        }
      } else {
        if (isScrollToTopButtonVisible) {
          setScrollTopButtonVisible(false)
          let scrollToTopButtonEl = document.getElementById(scrollToTopButtonElementId)
          scrollToTopButtonEl.style.visibility = '0'
          scrollToTopButtonEl.style.opacity = '0'
        }
      }
    }
  }

  /**
     *             securityOperationsCenterImage: file(
                relativePath: { eq: "image/services-soc.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 300, maxHeight: 450, quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                },
                publicURL
            },
            securityOperationsCenterParallaxImage: file(
                relativePath: { eq: "image/services-soc-parallax.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 300, maxHeight: 450, quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                },
                publicURL
            },
            incidentResponseImage: file(
                relativePath: { eq: "image/services-incident-response.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 300, maxHeight: 450, quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                },
                publicURL
            },
            incidentResponseParallaxImage: file(
                relativePath: { eq: "image/services-incident-response-parallax.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 300, maxHeight: 450, quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                },
                publicURL
            },
            consultingImage: file(
                relativePath: { eq: "image/services-consulting.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 300, maxHeight: 450, quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                },
                publicURL
            },
            managedServicesImage: file(
                relativePath: { eq: "image/services-managed-services.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 300, maxHeight: 450, quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                },
                publicURL
            },
            managedServicesParallaxImage: file(
                relativePath: { eq: "image/services-managed-services-parallax.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 300, maxHeight: 450, quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                },
                publicURL
            },
            testsAndAuditsImage: file(
                relativePath: { eq: "image/services-tests-audits.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 300, maxHeight: 450, quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                },
                publicURL
            },
     */

  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        SERVICES_PAGE {
          incidentResponse {
            title
            subTitle
            sectionTitle
            detailsPanel {
              title
              items
            }
            overviewPanel {
              paragraphs
            }
          }
          securityOperationsCenter {
            title
            subTitle
            sectionTitle
            detailsPanel {
              title
              items
            }
            overviewPanel {
              paragraphs
            }
          }
          consulting {
            title
            subTitle
            sectionTitle
            detailsPanel {
              title
              items
            }
            overviewPanel {
              introParagraphs
              midOverviewListItems
              closingParagraphs
            }
          }
          testsAndAudits {
            title
            subTitle
            sectionTitle
            detailsPanel {
              title
              items
            }
            overviewPanel {
              introParagraphs
              statisticsSection {
                title
                statistics
                statisticsAttribution
              }
              closingParagraphs
            }
          }
          managedServices {
            title
            subTitle
            sectionTitle
            sectionTitleSubTitle
            services {
              threatAndVulnerabilityManagement {
                calloutTitle
                calloutSubTitle
                sectionTitle
                detailsPanel {
                  title
                  items
                }
                overviewPanel {
                  paragraphs
                }
              }
              infrastructurePerformanceMonitoring {
                calloutTitle
                calloutSubTitle
                sectionTitle
                detailsPanel {
                  title
                  items
                }
                overviewPanel {
                  paragraphs
                }
              }
              managedEndpointProtection {
                calloutTitle
                calloutSubTitle
                sectionTitle
                detailsPanel {
                  title
                  items
                }
                overviewPanel {
                  paragraphs
                }
              }
              managedFirewall {
                calloutTitle
                calloutSubTitle
                sectionTitle
                detailsPanel {
                  title
                  items
                }
                overviewPanel {
                  paragraphs
                }
              }
              securityInformationAndEventManagement {
                calloutTitle
                calloutSubTitle
                sectionTitle
                detailsPanel {
                  introParagraph
                  title
                  items
                }
                overviewPanel {
                  introParagraphs
                  midOverviewListItems
                  closingParagraphs
                }
              }
            }
          }
        }
      }
    }
  `)

  const securityOperationsCenterContent = Data.dataJson.SERVICES_PAGE[0].securityOperationsCenter
  const incidentResponseContent = Data.dataJson.SERVICES_PAGE[0].incidentResponse
  const consultingContent = Data.dataJson.SERVICES_PAGE[0].consulting
  const testsAndAudits = Data.dataJson.SERVICES_PAGE[0].testsAndAudits
  const managedServices = Data.dataJson.SERVICES_PAGE[0].managedServices

  const SecurityOperationsCenterContent = () => {
    return (
      <div id='securityOperationsCenterContent'>
        <ContentContainer>
          <ContentRow>
            <OverviewPanel>
              {securityOperationsCenterContent.overviewPanel.paragraphs.map((paragraph, index) => (
                <Fragment key={index}>
                  <Text content={paragraph}></Text>
                </Fragment>
              ))}
            </OverviewPanel>
            <DetailsPanel>
              <div className='details-header'>{securityOperationsCenterContent.detailsPanel.title}</div>
              <ul className='details-panel-list-style'>
                {securityOperationsCenterContent.detailsPanel.items.map((item, index) => (
                  <Fragment key={index}>
                    <li>{item}</li>
                  </Fragment>
                ))}
              </ul>
            </DetailsPanel>
          </ContentRow>
        </ContentContainer>
      </div>
    )
  }

  const IncidentResponseContent = () => {
    return (
      <div id='incidentResponseContent'>
        <ContentContainer>
          <ContentRow>
            <DetailsPanel>
              <div className='details-header'>{incidentResponseContent.detailsPanel.title}</div>
              <ul className='details-panel-list-style'>
                {incidentResponseContent.detailsPanel.items.map((item, index) => (
                  <Fragment key={index}>
                    <li>{item}</li>
                  </Fragment>
                ))}
              </ul>
            </DetailsPanel>
            <OverviewPanel>
              {incidentResponseContent.overviewPanel.paragraphs.map((paragraph, index) => (
                <Fragment key={index}>
                  <Text content={paragraph}></Text>
                </Fragment>
              ))}
            </OverviewPanel>
          </ContentRow>
        </ContentContainer>
      </div>
    )
  }

  const ConsultingContent = () => {
    return (
      <div id='consultingContent'>
        <ContentContainer>
          <ContentRow>
            <OverviewPanel>
              {consultingContent.overviewPanel.introParagraphs.map((paragraph, index) => (
                <Fragment key={index}>
                  <Text content={paragraph}></Text>
                </Fragment>
              ))}
              <ul className='overview-panel-list-style'>
                {consultingContent.overviewPanel.midOverviewListItems.map((item, index) => (
                  <Fragment key={index}>
                    <li>{item}</li>
                  </Fragment>
                ))}
              </ul>
              {consultingContent.overviewPanel.closingParagraphs.map((paragraph, index) => (
                <Fragment key={index}>
                  <Text content={paragraph}></Text>
                </Fragment>
              ))}
            </OverviewPanel>
            <DetailsPanel>
              <div className='details-header'>{consultingContent.detailsPanel.title}</div>
              <ul className='details-panel-list-style'>
                {consultingContent.detailsPanel.items.map((item, index) => (
                  <Fragment key={index}>
                    <li>{item}</li>
                  </Fragment>
                ))}
              </ul>
            </DetailsPanel>
          </ContentRow>
        </ContentContainer>
      </div>
    )
  }

  const TestsAndAuditsContent = () => {
    return (
      <div id='testsAndAuditsContent'>
        <ContentContainer>
          <ContentRow>
            <DetailsPanel>
              <div className='details-header'>{testsAndAudits.detailsPanel.title}</div>
              <ul className='details-panel-list-style'>
                {testsAndAudits.detailsPanel.items.map((item, index) => (
                  <Fragment key={index}>
                    <li>{item}</li>
                  </Fragment>
                ))}
              </ul>
            </DetailsPanel>
            <OverviewPanel>
              {testsAndAudits.overviewPanel.introParagraphs.map((paragraph, index) => (
                <Fragment key={index}>
                  <Text content={paragraph}></Text>
                </Fragment>
              ))}
              <div>
                {testsAndAudits.overviewPanel.statisticsSection.title}
                <ul className='overview-panel-list-style'>
                  {testsAndAudits.overviewPanel.statisticsSection.statistics.map((statistic, index) => (
                    <Fragment key={index}>
                      <li>{statistic}</li>
                    </Fragment>
                  ))}
                </ul>
              </div>
              {testsAndAudits.overviewPanel.closingParagraphs.map((paragraph, index) => (
                <Fragment key={index}>
                  <Text content={paragraph}></Text>
                </Fragment>
              ))}
              <Text content={testsAndAudits.overviewPanel.statisticsSection.statisticsAttribution} {...statisticsAttribution}></Text>
            </OverviewPanel>
          </ContentRow>
        </ContentContainer>
      </div>
    )
  }

  const ManagedServicesContent = () => {
    return (
      <div id='managedServicesContent'>
        <ContentRow>
          <ServiceSubSectionTile>
            <ServicesSubTileContent>
              <div className='section-header'>{managedServices.services.threatAndVulnerabilityManagement.calloutTitle}</div>
              <div className='section-content'>
                {managedServices.services.threatAndVulnerabilityManagement.calloutSubTitle}
                <AnchorLink href={'#continuousThreatContent'} offset={75}>
                  <ScrollToSectionButton>
                    <button>
                      Read More <FiChevronDown size={25} />
                    </button>
                  </ScrollToSectionButton>
                </AnchorLink>
              </div>
            </ServicesSubTileContent>
          </ServiceSubSectionTile>
          <ServiceSubSectionTile>
            <ServicesSubTileContent>
              <div className='section-header'>{managedServices.services.infrastructurePerformanceMonitoring.calloutTitle}</div>
              <div className='section-content'>
                {managedServices.services.infrastructurePerformanceMonitoring.calloutSubTitle}
                <AnchorLink href={'#infrastructurePerformanceContent'} offset={75}>
                  <ScrollToSectionButton>
                    <button>
                      Read More <FiChevronDown size={25} />
                    </button>
                  </ScrollToSectionButton>
                </AnchorLink>
              </div>
            </ServicesSubTileContent>
          </ServiceSubSectionTile>
          <ServiceSubSectionTile>
            <ServicesSubTileContent>
              <div className='section-header'>{managedServices.services.managedEndpointProtection.calloutTitle}</div>
              <div className='section-content'>
                {managedServices.services.managedEndpointProtection.calloutSubTitle}
                <AnchorLink href={'#managedEndpointContent'} offset={75}>
                  <ScrollToSectionButton>
                    <button>
                      Read More <FiChevronDown size={25} />
                    </button>
                  </ScrollToSectionButton>
                </AnchorLink>
              </div>
            </ServicesSubTileContent>
          </ServiceSubSectionTile>
          <ServiceSubSectionTile>
            <ServicesSubTileContent>
              <div className='section-header'>{managedServices.services.managedFirewall.calloutTitle}</div>
              <div className='section-content'>
                {managedServices.services.managedFirewall.calloutSubTitle}
                <AnchorLink href={'#managedFirewallContent'} offset={75}>
                  <ScrollToSectionButton>
                    <button>
                      Read More <FiChevronDown size={25} />
                    </button>
                  </ScrollToSectionButton>
                </AnchorLink>
              </div>
            </ServicesSubTileContent>
          </ServiceSubSectionTile>
          <ServiceSubSectionTile>
            <ServicesSubTileContent>
              <div className='section-header'>{managedServices.services.securityInformationAndEventManagement.calloutTitle}</div>
              <div className='section-content'>
                {managedServices.services.securityInformationAndEventManagement.calloutSubTitle}
                <AnchorLink href={'#securityInformationContent'} offset={75}>
                  <ScrollToSectionButton>
                    <button>
                      Read More <FiChevronDown size={25} />
                    </button>
                  </ScrollToSectionButton>
                </AnchorLink>
              </div>
            </ServicesSubTileContent>
          </ServiceSubSectionTile>
        </ContentRow>

        <ContentContainer id='continuousThreatContent'>
          <ContentRow>
            <ContentRowHeader>
              <div>{managedServices.services.threatAndVulnerabilityManagement.sectionTitle}</div>
            </ContentRowHeader>
          </ContentRow>
          <ContentRow>
            <OverviewPanel>
              {managedServices.services.threatAndVulnerabilityManagement.overviewPanel.paragraphs.map((paragraph, index) => {
                return (
                  <Fragment key={index}>
                    <div>{paragraph}</div>
                    <br />
                    <br />
                  </Fragment>
                )
              })}
            </OverviewPanel>
            <DetailsPanel>
              <div className='details-header'>{managedServices.services.threatAndVulnerabilityManagement.detailsPanel.title}</div>
              <ul className='details-panel-list-style'>
                {managedServices.services.threatAndVulnerabilityManagement.detailsPanel.items.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <li>{item}</li>
                    </Fragment>
                  )
                })}
              </ul>
            </DetailsPanel>
          </ContentRow>
        </ContentContainer>

        <ContentContainer id='infrastructurePerformanceContent'>
          <ContentRow>
            <ContentRowHeader>
              <div>{managedServices.services.infrastructurePerformanceMonitoring.sectionTitle}</div>
            </ContentRowHeader>
          </ContentRow>
          <ContentRow>
            <DetailsPanel>
              <div className='details-header'>{managedServices.services.infrastructurePerformanceMonitoring.detailsPanel.title}</div>
              <ul className='details-panel-list-style'>
                {managedServices.services.infrastructurePerformanceMonitoring.detailsPanel.items.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <li>{item}</li>
                    </Fragment>
                  )
                })}
              </ul>
            </DetailsPanel>
            <OverviewPanel>
              {managedServices.services.infrastructurePerformanceMonitoring.overviewPanel.paragraphs.map((paragraph, index) => {
                return (
                  <Fragment key={index}>
                    <div>{paragraph}</div>
                    <br />
                    <br />
                  </Fragment>
                )
              })}
            </OverviewPanel>
          </ContentRow>
        </ContentContainer>

        <ContentContainer id='managedEndpointContent'>
          <ContentRow>
            <ContentRowHeader>
              <div>{managedServices.services.managedEndpointProtection.sectionTitle}</div>
            </ContentRowHeader>
          </ContentRow>
          <ContentRow>
            <OverviewPanel>
              {managedServices.services.managedEndpointProtection.overviewPanel.paragraphs.map((paragraph, index) => {
                return (
                  <Fragment key={index}>
                    <div>{paragraph}</div>
                    <br />
                    <br />
                  </Fragment>
                )
              })}
            </OverviewPanel>
            <DetailsPanel>
              <div className='details-header'>{managedServices.services.managedEndpointProtection.detailsPanel.title}</div>
              <ul className='details-panel-list-style'>
                {managedServices.services.managedEndpointProtection.detailsPanel.items.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <li>{item}</li>
                    </Fragment>
                  )
                })}
              </ul>
            </DetailsPanel>
          </ContentRow>
        </ContentContainer>

        <ContentContainer id='managedFirewallContent'>
          <ContentRow>
            <ContentRowHeader>
              <div>{managedServices.services.managedFirewall.sectionTitle}</div>
            </ContentRowHeader>
          </ContentRow>
          <ContentRow>
            <DetailsPanel>
              <div className='details-header'>{managedServices.services.managedFirewall.detailsPanel.title}</div>
              <ul className='details-panel-list-style'>
                {managedServices.services.managedFirewall.detailsPanel.items.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <li>{item}</li>
                    </Fragment>
                  )
                })}
              </ul>
            </DetailsPanel>
            <OverviewPanel>
              {managedServices.services.managedFirewall.overviewPanel.paragraphs.map((paragraph, index) => {
                return (
                  <Fragment key={index}>
                    <div>{paragraph}</div>
                    <br />
                    <br />
                  </Fragment>
                )
              })}
            </OverviewPanel>
          </ContentRow>
        </ContentContainer>

        <ContentContainer id='securityInformationContent'>
          <ContentRow>
            <ContentRowHeader>
              <div>{managedServices.services.securityInformationAndEventManagement.sectionTitle}</div>
            </ContentRowHeader>
          </ContentRow>
          <ContentRow>
            <OverviewPanel>
              {managedServices.services.securityInformationAndEventManagement.overviewPanel.introParagraphs.map((paragraph, index) => (
                <Fragment key={index}>
                  <Text content={paragraph}></Text>
                </Fragment>
              ))}
              <ul className='overview-panel-list-style'>
                {managedServices.services.securityInformationAndEventManagement.overviewPanel.midOverviewListItems.map((item, index) => (
                  <Fragment key={index}>
                    <li>{item}</li>
                  </Fragment>
                ))}
              </ul>
              {managedServices.services.securityInformationAndEventManagement.overviewPanel.closingParagraphs.map((paragraph, index) => (
                <Fragment key={index}>
                  <Text content={paragraph}></Text>
                </Fragment>
              ))}
            </OverviewPanel>
            <DetailsPanel>
              {managedServices.services.securityInformationAndEventManagement.detailsPanel.introParagraph}
              <br />
              <br />
              <div className='details-header'>{managedServices.services.securityInformationAndEventManagement.detailsPanel.title}</div>
              <ul className='details-panel-list-style'>
                {managedServices.services.securityInformationAndEventManagement.detailsPanel.items.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <li>{item}</li>
                    </Fragment>
                  )
                })}
              </ul>
            </DetailsPanel>
          </ContentRow>
        </ContentContainer>
      </div>
    )
  }

  return (
    <ContainerWrapper>
      <Scrollspy
        items={['securityOperationsCenterContent', 'incidentResponseContent', 'consultingContent', 'testsAndAuditsContent', 'managedServicesContent']}
        currentClassName='is-current'
      >
        <TileFlexRow>
          <ServiceSectionTile>
            <AnchorLink href={'#securityOperationsCenterContent'} offset={145}>
              <StaticImage style={imageStyle} src='../../../images/services-soc.jpg' layout='fullWidth' alt='securityOperationsCenterImage'></StaticImage>
              <ServicesTileContent>
                <div className='section-header'>{securityOperationsCenterContent.title}</div>
                <div className='section-content'>{securityOperationsCenterContent.subTitle}</div>
              </ServicesTileContent>
            </AnchorLink>
          </ServiceSectionTile>
          <ServiceSectionTile>
            <AnchorLink href={'#incidentResponseContent'} offset={145}>
              <StaticImage style={imageStyle} src='../../../images/services-incident-response.jpg' layout='fullWidth' alt='incidentResponseImage'></StaticImage>
              <ServicesTileContent>
                <div className='section-header'>{incidentResponseContent.title}</div>
                <div className='section-content'>{incidentResponseContent.subTitle}</div>
              </ServicesTileContent>
            </AnchorLink>
          </ServiceSectionTile>
          <ServiceSectionTile>
            <AnchorLink href={'#consultingContent'} offset={145}>
              <StaticImage style={imageStyle} src='../../../images/services-consulting.jpg' layout='fullWidth' alt='consultingImage'></StaticImage>
              <ServicesTileContent>
                <div className='section-header'>{consultingContent.title}</div>
                <div className='section-content'>{consultingContent.subTitle}</div>
              </ServicesTileContent>
            </AnchorLink>
          </ServiceSectionTile>
          <ServiceSectionTile>
            <AnchorLink href={'#testsAndAuditsContent'} offset={145}>
              <StaticImage style={imageStyle} src='../../../images/services-tests-audits.jpg' layout='fullWidth' alt='testsAndAuditsImage'></StaticImage>
              <ServicesTileContent>
                <div className='section-header'>{testsAndAudits.title}</div>
                <div className='section-content'>{testsAndAudits.subTitle}</div>
              </ServicesTileContent>
            </AnchorLink>
          </ServiceSectionTile>
          <ServiceSectionTile>
            <AnchorLink href={'#managedServicesContent'} offset={155}>
              <StaticImage style={imageStyle} src='../../../images/services-managed-services.jpg' layout='fullWidth' alt='testsAndAuditsImage'></StaticImage>
              <ServicesTileContent>
                <div className='section-header'>{managedServices.title}</div>
                <div className='section-content'>{managedServices.subTitle}</div>
              </ServicesTileContent>
            </AnchorLink>
          </ServiceSectionTile>
        </TileFlexRow>
      </Scrollspy>

      <Parallax bgImage={securityOperationsCenterParallaxImage} strength={750}>
        <ParallaxImageWrapper>
          <ParallaxImageTextOverlayWrapper>
            <div>{securityOperationsCenterContent.sectionTitle}</div>
          </ParallaxImageTextOverlayWrapper>
        </ParallaxImageWrapper>
      </Parallax>
      <SecurityOperationsCenterContent></SecurityOperationsCenterContent>

      <Parallax bgImage={incidentResponseParallaxImage} strength={750}>
        <ParallaxImageWrapper>
          <ParallaxImageTextOverlayWrapper>
            <div>{incidentResponseContent.sectionTitle}</div>
          </ParallaxImageTextOverlayWrapper>
        </ParallaxImageWrapper>
      </Parallax>
      <IncidentResponseContent></IncidentResponseContent>

      <Parallax bgImage={consultingImage} strength={750}>
        <ParallaxImageWrapper>
          <ParallaxImageTextOverlayWrapper>
            <div>{consultingContent.sectionTitle}</div>
          </ParallaxImageTextOverlayWrapper>
        </ParallaxImageWrapper>
      </Parallax>
      <ConsultingContent></ConsultingContent>

      <Parallax bgImage={testsAndAuditsImage} strength={750}>
        <ParallaxImageWrapper>
          <ParallaxImageTextOverlayWrapper>
            <div>{testsAndAudits.sectionTitle}</div>
          </ParallaxImageTextOverlayWrapper>
        </ParallaxImageWrapper>
      </Parallax>
      <TestsAndAuditsContent></TestsAndAuditsContent>

      <Parallax bgImage={managedServicesParallaxImage} strength={750}>
        <ParallaxImageWrapper>
          <ParallaxImageTextOverlayWrapper>
            <div>{managedServices.sectionTitle}</div>
            <div style={{ ...subTitle }}>{managedServices.sectionTitleSubTitle}</div>
          </ParallaxImageTextOverlayWrapper>
        </ParallaxImageWrapper>
      </Parallax>
      <ManagedServicesContent></ManagedServicesContent>

      <ScrollToTopButton>
        <button
          id='scrollToTopButton'
          onClick={() => {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' })
          }}
        >
          <FiChevronUp size={25} />
        </button>
      </ScrollToTopButton>
    </ContainerWrapper>
  )
}

ServicesLandingSection.defaultProps = {
  imageStyle: {
    width: '100%',
    minHeight: '445px',
  },
  subTitle: {
    fontSize: '1.25rem',
  },
  statisticsAttribution: {
    fontStyle: 'italic',
    fontSize: '0.95rem',
  },
}

export default ServicesLandingSection
